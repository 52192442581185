import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['wrapper', 'links'];

  connect() {
  }

  toggle() {
    // strings as bools as we're dealing with DOM data attributes
    this.data.set('expanded', this.expanded ? 'false' : 'true');

    if (this.expanded) {
      this.wrapperTarget.classList.remove('quick-links--closed');
    } else {
      this.wrapperTarget.classList.add('quick-links--closed');
    }
  }

  get expanded() {
    return this.data.get('expanded') === 'true';
  }
}
