import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['modal', 'dialog'];

  initialize() {
    // Use this to identify the component when setting a class on body
    this.key = `${Math.random().toString(36).substr(2, 5)}`;

    this.dialogTarget.classList.add('modal__dialog--hidden');
    this.dialogTarget.setAttribute('aria-hidden', true);
    // this.dialogTarget.style.display = 'none';
    this.dialogTarget.hidden = true;

    this.dialogTarget.addEventListener('transitionend', (e) => {
      // transitionend will fire for each transition property - we only care if
      // its our element and choose one property to go with
      if(e.target !== this.dialogTarget || e.propertyName !== 'transform') {
        return;
      }

      // We use key to remove only our modal-open class
      if (this.dialogTarget.classList.contains('modal__dialog--hidden')) {
        document.body.classList.remove(`modal-open-key-${this.key}`);
        // this.dialogTarget.style.display = 'none';
        this.dialogTarget.hidden = true;
      }
    }, false);
  }

  open(e) {
    e.preventDefault();
    this.dialogTarget.setAttribute('aria-hidden', false);
    this.dialogTarget.classList.remove('modal__dialog--hidden');
    document.body.classList.add(`modal-open-key-${this.key}`);
    this.modalTarget.classList.add('modal--open');
    this.dialogTarget.hidden = false;
  }

  close() {
    this.dialogTarget.classList.add('modal__dialog--hidden');
    this.dialogTarget.setAttribute('aria-hidden', true);
    this.modalTarget.classList.remove('modal--open');
  }
}
