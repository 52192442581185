export const dateConstants = {
  MILLISECONDS_IN_YEAR: 31536000000,
  DAYS_IN_YEAR: 365,
  WEEKS_IN_YEAR: 52,
  MONTHS_IN_YEAR: 12,
  HOURS_IN_DAY: 24,
  MINUTES_IN_HOUR: 60,
  SECONDS_IN_MINUTE: 60,
  MILLISECONDS_IN_SECOND: 1000,
  MONTHS_OF_YEAR: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ],
};

export default dateConstants;
