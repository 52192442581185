export const NationalInsuranceBands = {
  Primary: 'primary',
  Upper: 'upper',
} as const;

const nationalInsuranceRates: NationalInsuranceRates[] = [
  {
    taxYear: '2020/21',
    bands: [
      {
        name: NationalInsuranceBands.Primary,
        rate: 0.12,
        earningsFrom: 9500,
      },
      {
        name: NationalInsuranceBands.Upper,
        rate: 0.02,
        earningsFrom: 50000,
      },
    ],
  },
  {
    taxYear: '2021/22',
    bands: [
      {
        name: NationalInsuranceBands.Primary,
        rate: 0.12,
        earningsFrom: 9568,
      },
      {
        name: NationalInsuranceBands.Upper,
        rate: 0.02,
        earningsFrom: 50270,
      },
    ],
  },
  {
    taxYear: '2022/23',
    bands: [
      {
        name: NationalInsuranceBands.Primary,
        rate: 0.1325,
        earningsFrom: 9880,
      },
      {
        name: NationalInsuranceBands.Upper,
        rate: 0.0325,
        earningsFrom: 50270,
      },
    ],
  },
  {
    taxYear: '2022/23-july',
    bands: [
      {
        name: NationalInsuranceBands.Primary,
        rate: 0.1325,
        earningsFrom: 12570,
      },
      {
        name: NationalInsuranceBands.Upper,
        rate: 0.0325,
        earningsFrom: 50270,
      },
    ],
  },
  {
    taxYear: '2022/23-november',
    bands: [
      {
        name: NationalInsuranceBands.Primary,
        rate: 0.12,
        earningsFrom: 12570,
      },
      {
        name: NationalInsuranceBands.Upper,
        rate: 0.02,
        earningsFrom: 50270,
      },
    ],
  },
  {
    taxYear: '2023/24',
    bands: [
      {
        name: NationalInsuranceBands.Primary,
        rate: 0.12,
        earningsFrom: 12570,
      },
      {
        name: NationalInsuranceBands.Upper,
        rate: 0.02,
        earningsFrom: 50270,
      },
    ],
  },
  {
    taxYear: '2023/24-january',
    bands: [
      {
        name: NationalInsuranceBands.Primary,
        rate: 0.1,
        earningsFrom: 12570,
      },
      {
        name: NationalInsuranceBands.Upper,
        rate: 0.02,
        earningsFrom: 50270,
      },
    ],
  },
  {
    taxYear: '2024/25',
    bands: [
      {
        name: NationalInsuranceBands.Primary,
        rate: 0.08,
        earningsFrom: 12570,
      },
      {
        name: NationalInsuranceBands.Upper,
        rate: 0.02,
        earningsFrom: 50270,
      },
    ],
  },
];

export default nationalInsuranceRates;
