// Return the value x restricted to the specified maximum and minimum
const bound = (min: number, x: number, max: number): number =>
  Math.max(min, Math.min(x, max));

// Return the value x that lies between bandmin and bandmax
// assumes all positive
// assumes bandmin < bandmax if this isn't true returns NaN
// needs to cope with three scenarios:
// i)    x <= bandmin result 0
// ii)   bandmin < x < bandmax result x-bandmin
// iii)  x >= bandmax result bandmax-bandmin
const band = (bandmin: number, x: number, bandmax: number): number => {
  if (bandmin < bandmax) {
    return Math.min(Math.max(0, x - bandmin), bandmax - bandmin);
  } else if (bandmin === bandmax) {
    return 0;
  }
  return Number.NaN;
};

// Return the value of x rounded to 10^n, -ve and +ve allowed
// eg:
// round(12345.6789, -3) = 12345.679
// round(12345.6789, 3) = 12000
const round = (x: number, n: number): number => {
  let temporary = x / Math.pow(10, n);
  temporary = correctRoundingError(temporary + 0.5) - 0.5;
  return Math.round(temporary) * Math.pow(10, n);
};

// Return the value of x floored to 10^n, -ve and +ve allowed
// eg:
// floor(12345.6789, -3) = 12345.678
// floor(98765.4321, 3) = 98000
const floor = (x: number, n: number): number =>
  Math.floor(x / Math.pow(10, n)) * Math.pow(10, n);

// Return the value of x rounded to any integer within 1/10000000
// this counteracts the problems that occur from precision related division limitations.
const correctRoundingError = (x: number): number =>
  Math.round(x * 100000000) / 100000000;

export const mathUtils = {
  bound,
  band,
  round,
  floor,
  correctRoundingError,
};

export default mathUtils;
