$(document).ready(function () {
  var $additionalMessage = $('.page-feedback--content');
  var $scores = $('.page-feedback--score');
  var $outcome = $('.page-feedback--outcome');


  $outcome.hide();

  $('.page-feedback-side-header').click(function (event) {
    $(this).parent().toggleClass('open');

  });

  $scores.click(function (event) {
    var $this = $(this);
    $scores.removeClass('active filled');

    $("[data-score=" + $this.attr('data-score') + "]").addClass('active');

    // Fill in all stars below the one clicked too.
    for (var i = $this.attr('data-score')-1; i > 0; i--) {
      $('.page-feedback--score.'+i+'-star').addClass('filled')
    }

    $additionalMessage.removeClass('hide');
    event.preventDefault();
  });

  $('.page-feedback--submit').click(function (event) {
    var $score = $('.page-feedback--score.active');

    if (!$score.length) {
      $outcome.show().text('Please submit a score');
      return false;
    }

    $outcome.hide();

    var feedback = {
      score: parseInt($score.attr('data-score')),
      url: window.location.href,
      year_of_birth: $('#year_of_birth').attr('value'),
      approx_salary: $('#approx_salary').attr('value'),
      statement_type_trigger: $('#statement_type_trigger').attr('value'),
      elements_level: $('#elements_level').attr('value'),
      flexi_credit_category: $('#flexi_credit_category').attr('value'),
      final_salary: $('#final_salary').attr('value'),
      jmeps_extra_member: $('#jmeps_extra_member').attr('value'),
      isave_conts_present: $('#isave_conts_present').attr('value')
    };
    console.log(feedback);

    if (!$additionalMessage.hasClass('hide')) {
      feedback.message = $(this).parent().parent().find('textarea').val();
    }

    $.post('/page_feedbacks', {
      feedback: feedback
    }).done(function (data) {
      if (data.id) {
        $('.page-feedback--content-submitted').removeClass('hide');
        $('.page-feedback--content-unsubmitted').addClass('hide');
        $('.page-feedback--heading').addClass('hide');
        setTimeout(function(){
          $('#page-feedback').removeClass('open');
          $('.page-feedback--content-submitted').addClass('hide');
          $('.page-feedback--content-unsubmitted').removeClass('hide');
          $('.page-feedback--heading').removeClass('hide');
          $('.page-feedback--score').removeClass('active filled');
          $additionalMessage.addClass('hide');
          $('#additional').val('');
        }, 3000);
      } else {
        $outcome.text(data[0]);
      }
    }).fail(function (data) {
      console.log('failed', data);
    });

    event.preventDefault();
  });
});
