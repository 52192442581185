import { Controller } from 'stimulus'
export default class extends Controller {
  static targets = ['wrapper', 'button', 'buttonText', 'nav', 'link'];

  connect() {
    this.disableLinks();
  }

  toggle() {
    // strings as bools as we're dealing with DOM data attributes
    this.data.set('expanded', this.expanded ? 'false' : 'true');

    this.wrapperTarget.classList.toggle('menu--expanded', this.expanded);
    this.navTarget.classList.toggle('menu__items--expanded', this.expanded);
    this.buttonTarget.classList.toggle('menu__toggle--open', this.expanded);

    this.buttonTarget.setAttribute('aria-expanded', this.expanded);
    this.buttonTextTarget.textContent = this.expanded ? 'Close' : 'Menu';

    // hide feedback widget so it doesn't interfere with menu
    document.getElementById('page-feedback').classList.toggle('collapse', this.expanded);

    if (this.expanded) {
      this.enableLinks();
    } else {
      this.disableLinks();
    }
  }

  disableLinks() {
    this.linkTargets.forEach(el => el.setAttribute('tabIndex', -1));
  }

  enableLinks() {
    this.linkTargets.forEach(el => el.removeAttribute('tabIndex'));
  }

  get expanded() {
    return this.data.get('expanded') === 'true';
  }
}
