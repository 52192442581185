// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import '@stimulus/polyfills';

require('@rails/ujs').start()
// require('turbolinks').start()
require('@rails/activestorage').start()
require('channels')

require('jquery') // TODO: Once feedback tool is rewritten we can delete the following jquery stuff. In enviroment.js and yarn too.

require('packs/page_feedback')
require('packs/timeout_warning')

import '../components';
import '../stylesheets/application';

// Polyfill Array.forEach (IE11)
if ('NodeList' in window && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

const application = Application.start()
const componentsContext = require.context('../../components', true, /(.*)\/.+\.js$/)
application.load(
  definitionsFromContext(componentsContext)
)
