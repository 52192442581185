export const Locales = {
  England: 'GB-ENG',
  Scotland: 'GB-SCT',
  Wales: 'GB-WLS',
  NorthernIreland: 'GB-NIR',
} as const;

export const TaxBands = {
  Starter: 'starter',
  Basic: 'basic',
  Intermediate: 'intermediate',
  Higher: 'higher',
  Additional: 'additional',
  Top: 'top',
} as const;

export const TaxYears = {
  TaxYear2020_21: '2020/21',
  TaxYear2021_22: '2021/22',
  TaxYear2022_23: '2022/23',
  TaxYear2022_23July: '2022/23-july',
  TaxYear2022_23November: '2022/23-november',
  TaxYear2023_24: '2023/24',
  TaxYear2023_24January: '2023/24-january',
  TaxYear2024_25: '2024/25',
} as const;
