import { dateUtils } from '../../../utils/advanced-date';

//where these relate to an interpolated/graduated band they reflect the maximum/starting point of the next
const PHASE_1_DOB_START = dateUtils.parseDateInput('06/04/1950');
const PHASE_2_DOB_START = dateUtils.parseDateInput('06/04/1953');
const PHASE_3_DOB_START = dateUtils.parseDateInput('06/12/1953');
const PHASE_4_DOB_START = dateUtils.parseDateInput('06/10/1954');
const PHASE_5_DOB_START = dateUtils.parseDateInput('06/04/1960');
const PHASE_6_DOB_START = dateUtils.parseDateInput('06/03/1961');
const PHASE_7_DOB_START = dateUtils.parseDateInput('06/04/1977');
const PHASE_8_DOB_START = dateUtils.parseDateInput('06/04/1978');

//State pension ages in years:
const SPA_PHASE_0_FEMALE = 60;
const SPA_PHASE_0_MALE = 65;
const SPA_PHASE_1_MALE = 65;
const SPA_PHASE_2_MALE = 65;
const SPA_PHASE_4 = 66;
const SPA_PHASE_6 = 67;
const SPA_PHASE_8 = 68;

const PHASE_1_SPD_START_FEMALE = dateUtils.parseDateInput('06/05/2010');
const PHASE_2_SPD_START_FEMALE = dateUtils.parseDateInput('06/07/2016');
const PHASE_3_SPD_START = dateUtils.parseDateInput('06/03/2019');
const PHASE_7_SPD_START = dateUtils.parseDateInput('06/05/2044');

const PHASE_1_INCREASE_RATE_FEMALE = 2;
const PHASE_2_INCREASE_RATE_FEMALE = 4;
const PHASE_3_INCREASE_RATE = 2;
const PHASE_7_INCREASE_RATE = 2;

export const statePensionDate = (gender: Gender, dateOfBirth: Date): Date => {
  if (dateUtils.before(dateOfBirth, PHASE_1_DOB_START)) {
    return phase0PensionDate(dateOfBirth, gender);
  } else if (dateUtils.before(dateOfBirth, PHASE_2_DOB_START)) {
    return phase1PensionDate(dateOfBirth, gender);
  } else if (dateUtils.before(dateOfBirth, PHASE_3_DOB_START)) {
    return phase2PensionDate(dateOfBirth, gender);
  } else if (dateUtils.before(dateOfBirth, PHASE_4_DOB_START)) {
    return phase3PensionDate(dateOfBirth);
  } else if (dateUtils.before(dateOfBirth, PHASE_5_DOB_START)) {
    return phase4PensionDate(dateOfBirth);
  } else if (dateUtils.before(dateOfBirth, PHASE_6_DOB_START)) {
    return phase5PensionDate(dateOfBirth);
  } else if (dateUtils.before(dateOfBirth, PHASE_7_DOB_START)) {
    return phase6PensionDate(dateOfBirth);
  } else if (dateUtils.before(dateOfBirth, PHASE_8_DOB_START)) {
    return phase7PensionDate(dateOfBirth);
  } else {
    return phase8PensionDate(dateOfBirth);
  }
};

const phase0PensionDate = (dateOfBirth: Date, gender: Gender): Date => {
  return gender.toUpperCase() === 'FEMALE'
    ? birthdayAtPensionAge(dateOfBirth, SPA_PHASE_0_FEMALE)
    : birthdayAtPensionAge(dateOfBirth, SPA_PHASE_0_MALE);
};

const phase1PensionDate = (dateOfBirth: Date, gender: Gender): Date => {
  return gender.toUpperCase() === 'FEMALE'
    ? phasedIncrease(
        dateOfBirth,
        PHASE_1_DOB_START,
        PHASE_1_SPD_START_FEMALE,
        PHASE_1_INCREASE_RATE_FEMALE
      )
    : birthdayAtPensionAge(dateOfBirth, SPA_PHASE_1_MALE);
};

const phase2PensionDate = (dateOfBirth: Date, gender: Gender): Date => {
  return gender.toUpperCase() === 'FEMALE'
    ? phasedIncrease(
        dateOfBirth,
        PHASE_2_DOB_START,
        PHASE_2_SPD_START_FEMALE,
        PHASE_2_INCREASE_RATE_FEMALE
      )
    : birthdayAtPensionAge(dateOfBirth, SPA_PHASE_2_MALE);
};

const phase3PensionDate = (dateOfBirth: Date): Date => {
  return phasedIncrease(
    dateOfBirth,
    PHASE_3_DOB_START,
    PHASE_3_SPD_START,
    PHASE_3_INCREASE_RATE
  );
};

const phase4PensionDate = (dateOfBirth: Date): Date => {
  return birthdayAtPensionAge(dateOfBirth, SPA_PHASE_4);
};

const phase5PensionDate = (dateOfBirth: Date): Date => {
  const additionalMonths =
    dateUtils.monthsBetween(PHASE_5_DOB_START, dateOfBirth) + 1;
  return dateUtils.addMonths(
    dateUtils.parseDateInput(dateOfBirth),
    additionalMonths + SPA_PHASE_4 * 12
  );
};

const phase6PensionDate = (dateOfBirth: Date): Date => {
  return birthdayAtPensionAge(dateOfBirth, SPA_PHASE_6);
};

const phase7PensionDate = (dateOfBirth: Date): Date => {
  return phasedIncrease(
    dateOfBirth,
    PHASE_7_DOB_START,
    PHASE_7_SPD_START,
    PHASE_7_INCREASE_RATE
  );
};

const phase8PensionDate = (dateOfBirth: Date): Date => {
  return birthdayAtPensionAge(dateOfBirth, SPA_PHASE_8);
};

const phasedIncrease = (
  dateOfBirth: Date,
  phaseDateOfBirthStart: Date,
  phaseRetirementDateStart: Date,
  multiplier: number
): Date => {
  const additionalMonths = dateUtils.after(dateOfBirth, phaseDateOfBirthStart)
    ? multiplier * dateUtils.monthsBetween(phaseDateOfBirthStart, dateOfBirth)
    : 0;

  return dateUtils.addMonths(
    dateUtils.parseDateInput(phaseRetirementDateStart),
    additionalMonths
  );
};

export const birthdayAtPensionAge = (
  dateOfBirth: Date,
  pensionAge: number
): Date => {
  const duplicateDate = dateUtils.parseDateInput(dateOfBirth);
  duplicateDate.setFullYear(dateOfBirth.getFullYear() + pensionAge);
  return duplicateDate;
};

export default statePensionDate;
