// Proof of concept to redirect people to the logout page on 15 min of inactivity, after a warning.

const WARN_TIME_MINUTES = 13
const LOGOUT_TIME_MINUTES = 15 // Set this to whatever is defined as config.timeout_minutes in /config/enviroment/production.rb

const KEEP_ALIVE_URL = '/welcome/keep_alive'

window.onload = function() {
  if (document.getElementById('timeout_warning') != null) {
    // Only run on pages where the warning is in DOM (only when logged in basically)
    startTimers();

    continue_session_link = document.getElementById('continue_session');

    continue_session_link.onclick = function(e) {
      SendKeepAliveRequest();
      resetTimers();
      document.getElementById('timeout_warning').classList.add("timeout_warning--hide")
      console.log("Extended session by " + LOGOUT_TIME_MINUTES + " min.");
      e.preventDefault();
    }
  }
};

function startTimers() {
  warn_timer = setTimeout( function() {
    document.getElementById('timeout_warning').classList.remove("timeout_warning--hide")
  }, WARN_TIME_MINUTES * 60 * 1000);

  logout_timer = setTimeout(function() {
    // Hard logout, Rails session will have expired already at this point so there's no saving it.
      window.location = "/saml/logout?logout_reason=timeout";
  }, LOGOUT_TIME_MINUTES * 60 * 1000);
}

function resetTimers(){
  window.clearTimeout(warn_timer);
  window.clearTimeout(logout_timer);
  startTimers();
}

function SendKeepAliveRequest(callback){
  var xmlHttp = new XMLHttpRequest();
  xmlHttp.onreadystatechange = function() {
      if (xmlHttp.readyState == 4 && xmlHttp.status == 200)
          callback(xmlHttp.responseText);
  }
  xmlHttp.open("GET", KEEP_ALIVE_URL, true); // true for asynchronous
  xmlHttp.send(null);
}
