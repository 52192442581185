import { Controller } from 'stimulus'
import _ from 'lodash';

export default class extends Controller {
  static targets = ['header'];

  connect() {
    const wrapperElement = document.querySelector('#outer-wrapper');
    wrapperElement.addEventListener('scroll', this.onScroll);
  }

  onScroll = _.debounce((e) => {
    const mainElement = document.querySelector('#main-content');
    const coordinates = mainElement.getBoundingClientRect();
    let scrollPosition = coordinates.top;
    if (scrollPosition < 0){
      this.headerTarget.classList.add('header--sticky');
    } else {
      this.headerTarget.classList.remove('header--sticky');
    }
  }, 64);
}
