import dwpPensionRates from './rates';

const ratesForTaxYear = (taxYear: string): DwpPensionRatesWithAllowances => {
  for (const rates of dwpPensionRates) {
    if (rates.taxYear === taxYear) {
      return rates;
    }
  }

  console.warn(
    `DWP pension rates for ${taxYear} not found, defaulting to latest`
  );

  return dwpPensionRates.sort(
    (firstElement, secondElement) =>
      Number.parseInt(firstElement.taxYear) -
      Number.parseInt(secondElement.taxYear)
  )[dwpPensionRates.length - 1];
};

export const DWP = {
  ratesForTaxYear,
};

export default DWP;
