import englandTaxRates from './rates/england';
import scotlandTaxRates from './rates/scotland';

import { Locales } from './constants';

const ratesForTaxYear = (
  taxYear: string,
  locale: Locale = Locales.England
): TaxRatesWithAllowance => {
  let localeRates: TaxRatesWithAllowance[];
  switch (locale) {
    case Locales.England:
    case Locales.Wales:
    case Locales.NorthernIreland:
      localeRates = englandTaxRates;
      break;
    case Locales.Scotland:
      localeRates = scotlandTaxRates;
      break;
    default:
      throw new Error(`GB tax rates for locale ${locale} not found`);
  }

  let ratesForTaxYear: TaxRatesWithAllowance | undefined;
  for (const rates of localeRates) {
    if (rates.taxYear === taxYear) {
      ratesForTaxYear = rates;
    }
  }

  if (!ratesForTaxYear) {
    console.warn(
      `Tax rates for ${locale} ${taxYear} not found, defaulting to latest`
    );

    ratesForTaxYear = localeRates.sort(
      (firstElement, secondElement) =>
        Number.parseInt(firstElement.taxYear) -
        Number.parseInt(secondElement.taxYear)
    )[localeRates.length - 1];
  }

  ratesForTaxYear.bands.sort(
    (firstBand, secondBand) => firstBand.earningsFrom - secondBand.earningsFrom
  );

  return ratesForTaxYear;
};

const adjustedPersonalAllowance = (
  earnings: number,
  ratesWithAllowance: TaxRatesWithAllowance
): number => {
  const {
    full: personalAllowance,
    restrictionThreshold,
    reductionRate,
  } = ratesWithAllowance.personalAllowance;

  const earningsOverThreshold = Math.max(0, earnings - restrictionThreshold);

  const personalAllowanceReduction = Math.min(
    personalAllowance,
    earningsOverThreshold * reductionRate
  );

  return personalAllowance - personalAllowanceReduction;
};

const annualPayable = (
  earnings: number,
  taxYear: string,
  locale: Locale = Locales.England
): TaxBreakdown => {
  const ratesWithAllowance = ratesForTaxYear(taxYear, locale);

  const taxableEarnings = Math.max(
    0,
    earnings - adjustedPersonalAllowance(earnings, ratesWithAllowance)
  );

  let total = 0;
  let bandTax: number;
  const bandBreakdown: CollectionPayableBandTax = {};
  for (const [index, band] of ratesWithAllowance.bands.entries()) {
    const nextBand = ratesWithAllowance.bands[index + 1];

    const bandCeiling = nextBand
      ? nextBand.earningsFrom
      : Number.POSITIVE_INFINITY;

    bandTax =
      band.rate *
      Math.max(0, Math.min(bandCeiling, taxableEarnings) - band.earningsFrom);

    bandBreakdown[band.name] = {
      ...band,
      annualPayable: bandTax,
    };

    total += bandTax;
  }

  return {
    total,
    bandBreakdown,
  };
};

export const incomeTax = {
  ratesForTaxYear,
  adjustedPersonalAllowance,
  annualPayable,
};

export default incomeTax;
