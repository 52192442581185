import { Controller } from 'stimulus'
import _ from 'lodash';

export default class extends Controller {
  static targets = ['wrapper'];

  connect() {
    window.addEventListener('load', () => {
      this.layout();
    })
  }

  // reflow height on resize, but throttle for performance reasons
  layout = _.throttle(() => {
    this.onUpdateLayout();
  }, 16);

  // Due to the comparison table rendering in columns but also having row
  // headings that need to align across all items - we need to calculate
  // (and set) the height of each item. It's not possible with Flexbox to
  // align row content across different columns containers.
  onUpdateLayout() {
    const matchesMq = window.matchMedia( '(min-width: 48rem)' ).matches;
    const itemElementData = {};
    const elements = this.element.querySelectorAll('[data-name]');

    Array.prototype.map.call(elements, (el) => {
      if(itemElementData[el.dataset.name] === undefined) {
        itemElementData[el.dataset.name] = [];
      }
      // remove inline styles first
      el.removeAttribute('style');
      itemElementData[el.dataset.name].push({
        height: el.clientHeight,
        element: el,
      });
    });

    if (matchesMq) {
      Object.keys(itemElementData).forEach((key) => {
        const maxHeight = Math.max.apply(Math, itemElementData[key].map(obj => obj.height));

        // set matching elements to match height of tallest
        Array.prototype.forEach.call(itemElementData[key], (elData) => {
          elData.element.style.minHeight = `${ maxHeight}px`;
        });
      });
    }

    if (!this.wrapperTarget.classList.contains('comparison-table--fade-in')) {
      this.wrapperTarget.classList.add('comparison-table--fade-in');
    }
  }

}
