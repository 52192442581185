import { TaxBands } from '../constants';

const scotlandTaxRates: TaxRatesWithAllowance[] = [
  {
    taxYear: '2020/21',
    bands: [
      {
        name: TaxBands.Starter,
        rate: 0.19,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 2085,
      },
      {
        name: TaxBands.Intermediate,
        rate: 0.21,
        earningsFrom: 12658,
      },
      {
        name: TaxBands.Higher,
        rate: 0.41,
        earningsFrom: 30930,
      },
      {
        name: TaxBands.Additional,
        rate: 0.46,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12500,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2021/22',
    bands: [
      {
        name: TaxBands.Starter,
        rate: 0.19,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 2097,
      },
      {
        name: TaxBands.Intermediate,
        rate: 0.21,
        earningsFrom: 12726,
      },
      {
        name: TaxBands.Higher,
        rate: 0.41,
        earningsFrom: 31092,
      },
      {
        name: TaxBands.Additional,
        rate: 0.46,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2022/23',
    bands: [
      {
        name: TaxBands.Starter,
        rate: 0.19,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 2162,
      },
      {
        name: TaxBands.Intermediate,
        rate: 0.21,
        earningsFrom: 13118,
      },
      {
        name: TaxBands.Higher,
        rate: 0.41,
        earningsFrom: 31092,
      },
      {
        name: TaxBands.Additional,
        rate: 0.46,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2022/23-july',
    bands: [
      {
        name: TaxBands.Starter,
        rate: 0.19,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 2162,
      },
      {
        name: TaxBands.Intermediate,
        rate: 0.21,
        earningsFrom: 13118,
      },
      {
        name: TaxBands.Higher,
        rate: 0.41,
        earningsFrom: 31092,
      },
      {
        name: TaxBands.Additional,
        rate: 0.46,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2022/23-november',
    bands: [
      {
        name: TaxBands.Starter,
        rate: 0.19,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 2162,
      },
      {
        name: TaxBands.Intermediate,
        rate: 0.21,
        earningsFrom: 13118,
      },
      {
        name: TaxBands.Higher,
        rate: 0.41,
        earningsFrom: 31092,
      },
      {
        name: TaxBands.Additional,
        rate: 0.46,
        earningsFrom: 150000,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2023/24',
    bands: [
      {
        name: TaxBands.Starter,
        rate: 0.19,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 2162,
      },
      {
        name: TaxBands.Intermediate,
        rate: 0.21,
        earningsFrom: 13118,
      },
      {
        name: TaxBands.Higher,
        rate: 0.42,
        earningsFrom: 31092,
      },
      {
        name: TaxBands.Additional,
        rate: 0.47,
        earningsFrom: 125140,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2023/24-january',
    bands: [
      {
        name: TaxBands.Starter,
        rate: 0.19,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 2162,
      },
      {
        name: TaxBands.Intermediate,
        rate: 0.21,
        earningsFrom: 13118,
      },
      {
        name: TaxBands.Higher,
        rate: 0.42,
        earningsFrom: 31092,
      },
      {
        name: TaxBands.Additional,
        rate: 0.47,
        earningsFrom: 125140,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
  {
    taxYear: '2024/25',
    bands: [
      {
        name: TaxBands.Starter,
        rate: 0.19,
        earningsFrom: 0,
      },
      {
        name: TaxBands.Basic,
        rate: 0.2,
        earningsFrom: 2306,
      },
      {
        name: TaxBands.Intermediate,
        rate: 0.21,
        earningsFrom: 13991,
      },
      {
        name: TaxBands.Higher,
        rate: 0.42,
        earningsFrom: 31092,
      },
      {
        name: TaxBands.Additional,
        rate: 0.45,
        earningsFrom: 62430,
      },
      {
        name: TaxBands.Top,
        rate: 0.48,
        earningsFrom: 125140,
      },
    ],
    personalAllowance: {
      full: 12570,
      restrictionThreshold: 100000,
      reductionRate: 0.5, // 50p per £1 over restrictionThreshold
    },
  },
];

export default scotlandTaxRates;
