import nationalInsuranceRates from './rates';

const ratesForTaxYear = (taxYear: string): NationalInsuranceRates => {
  let ratesForTaxYear: NationalInsuranceRates | undefined;
  for (const rates of nationalInsuranceRates) {
    if (rates.taxYear === taxYear) {
      ratesForTaxYear = rates;
    }
  }

  if (!ratesForTaxYear) {
    console.warn(
      `National Insurance rates for ${taxYear} not found, defaulting to latest`
    );

    ratesForTaxYear = nationalInsuranceRates.sort(
      (firstElement, secondElement) =>
        Number.parseInt(firstElement.taxYear) -
        Number.parseInt(secondElement.taxYear)
    )[nationalInsuranceRates.length - 1];
  }

  ratesForTaxYear.bands.sort(
    (firstBand, secondBand) => firstBand.earningsFrom - secondBand.earningsFrom
  );

  return ratesForTaxYear;
};

const annualPayable = (
  earnings: number,
  taxYear: string
): NationalInsuranceBreakdown => {
  const ratesWithAllowance = ratesForTaxYear(taxYear);

  let total = 0;
  let bandNationalInsurance: number;
  const bandBreakdown: CollectionPayableBandNationalInsurance = {};
  for (const [index, band] of ratesWithAllowance.bands.entries()) {
    const nextBand = ratesWithAllowance.bands[index + 1];

    const bandCeiling = nextBand
      ? nextBand.earningsFrom
      : Number.POSITIVE_INFINITY;

    bandNationalInsurance =
      band.rate *
      Math.max(0, Math.min(bandCeiling, earnings) - band.earningsFrom);

    bandBreakdown[band.name] = {
      ...band,
      annualPayable: bandNationalInsurance,
    };

    total += bandNationalInsurance;
  }

  return {
    total,
    bandBreakdown,
  };
};

export const nationalInsurance = {
  annualPayable,
  ratesForTaxYear,
};

export default nationalInsurance;
