const formatCurrency = (
  number: number,
  precision = 0,
  currency = 'GBP',
  locale = 'en-GB'
): string =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: precision,
    currencyDisplay: 'narrowSymbol',
  }).format(number);

// 3 significant figures over £1,000
// below £1,000, round down to nearest £10
const smpiRounded = (number: number): string => {
  const rounded: string | number =
    number >= 1000
      ? Number(number.toPrecision(3))
      : Math.floor(number / 10) * 10;
  return formatCurrency(rounded, 0, 'GBP', 'en-GB');
};

export const stringUtils = {
  formatCurrency,
  smpiRounded,
};

export default stringUtils;
