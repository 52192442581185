import dateConstants from './constants';
import { Locale } from './enums';

// Functions to parse strings and numbers into date objects.
// The valid input formats are specified as types in "types.d.ts"
const parseDateInput = (...dateInput: unparsedUserDate): Date => {
  if (typeof dateInput[0] === 'undefined') {
    return new Date();
  } else if (validStringArgument(dateInput)) {
    return createDateFromString(dateInput);
  } else if (validNumericalArguments(dateInput)) {
    return createDateFromNumbers(dateInput);
  } else if (validObjectArgument(dateInput)) {
    return createDateFromObject(dateInput);
  } else {
    throw new Error('Unsupported date format');
  }
};

const validStringArgument = (
  dateInput: unparsedUserDate
): dateInput is unparsedDateString => {
  return typeof dateInput[0] === 'string' && dateInput.length === 1;
};

const validNumericalArguments = (
  dateInput: unparsedUserDate
): dateInput is unparsedDateNumbers => {
  return (
    (dateInput.length === 3 || dateInput.length === 1) &&
    dateInput.find(value => typeof value !== 'number') === undefined
  );
};

const validObjectArgument = (
  dateInput: unparsedUserDate
): dateInput is unparsedDateObject => {
  return dateInput.length === 1 && dateInput[0] instanceof Date;
};

const createDateFromString = (dateInput: [string]): Date => {
  const dateComponents = splitStringDate(...dateInput);

  const [year, month, day] =
    dateComponents[0] > 31 ? dateComponents : dateComponents.reverse();

  return new Date(year, month - 1, day);
};

const createDateFromObject = (dateInput: Date[]): Date => {
  return new Date(dateInput[0].getTime());
};

const createDateFromNumbers = (dateInput: number[]): Date => {
  if (dateInput.length === 3) {
    const [year, month, day] = dateInput;
    return new Date(year, month, day);
  } else {
    return new Date(dateInput[0]);
  }
};

const splitStringDate = (dateInput: string): number[] => {
  const parts = dateInput.split(/[/-]/);
  return [
    Number.parseInt(parts[0], 10),
    Number.parseInt(parts[1], 10),
    Number.parseInt(parts[2], 10),
  ];
};

const compareDateObjects = (startDate: Date, endDate: Date): number => {
  const [startDay, startMonth, startYear] = [
    startDate.getDate(),
    startDate.getMonth() + 1,
    startDate.getFullYear(),
  ];
  const [endDay, endMonth, endYear] = [
    endDate.getDate(),
    endDate.getMonth() + 1,
    endDate.getFullYear(),
  ];

  return compareDates(
    startDay,
    startMonth,
    startYear,
    endDay,
    endMonth,
    endYear
  );
};

const compareDates = (
  startDay: number,
  startMonth: number,
  startYear: number,
  endDay: number,
  endMonth: number,
  endYear: number
): number => {
  const endYearAsFloat =
    endYear +
    daysToDayOfMonth(endDay, endMonth, endYear, true) /
      dateConstants.DAYS_IN_YEAR;
  const startYearAsFloat =
    startYear +
    daysToDayOfMonth(startDay, startMonth, startYear, true) /
      dateConstants.DAYS_IN_YEAR;
  return endYearAsFloat - startYearAsFloat;
};

// Return the number of days since the start of the year (1st January)
const daysToDayOfMonth = (
  day: number,
  month: number,
  year: number,
  disregardLeapYears?: boolean
): number => {
  let days = day;
  for (let index = 1; index < month; index++) {
    days += daysInMonth(index, year, disregardLeapYears);
  }
  return days;
};

// Return the decimal year period rounded to the nearest month
const yearsAndCompleteMonths = (period: number): number => {
  //this can be achieved by rounding to nearest 1/12
  return (
    Math.round(period * dateConstants.MONTHS_IN_YEAR) /
    dateConstants.MONTHS_IN_YEAR
  );
};

const addYears = (date: Date, years: number): Date => {
  return new Date(date.getFullYear() + years, date.getMonth(), date.getDate());
};

const addMonths = (date: Date, months: number): Date => {
  return new Date(date.getFullYear(), date.getMonth() + months, date.getDate());
};

const equals = (date1: Date, date2: Date): boolean => {
  return date1.getTime() === date2.getTime();
};

const laterOf = (date1: Date, date2: Date): Date => {
  return date1.getTime() > date2.getTime() ? date1 : date2;
};

const earlierOf = (date1: Date, date2: Date): Date => {
  return date1.getTime() < date2.getTime() ? date1 : date2;
};

const after = (date1: Date, date2: Date): boolean => {
  return date1.getTime() > date2.getTime();
};

const before = (date1: Date, date2: Date): boolean => {
  return date1.getTime() < date2.getTime();
};

const afterOrOn = (date1: Date, date2: Date): boolean => {
  return date1.getTime() >= date2.getTime();
};

const beforeOrOn = (date1: Date, date2: Date): boolean => {
  return date1.getTime() <= date2.getTime();
};

const firstOfNextMonth = (date: Date): Date => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 1);
};

// Returns the number of whole months between 2 dates
const monthsBetween = (startDate: Date, endDate: Date): number => {
  if (endDate.getTime() >= startDate.getTime()) {
    const months =
      (endDate.getFullYear() - startDate.getFullYear()) *
        dateConstants.MONTHS_IN_YEAR +
      endDate.getMonth() -
      startDate.getMonth();
    return startDate.getDate() > endDate.getDate() ? months - 1 : months;
  }
  return -1;
};

// Returns the number of days in a month or NaN if the input month number is invalid. Months are indexed as 1-12.
const daysInMonth = (
  month: number,
  year: number,
  disregardLeapYears?: boolean
): number => {
  switch (month) {
    case 9:
    case 4:
    case 6:
    case 11: {
      return 30;
    }
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12: {
      return 31;
    }
    case 2: {
      return !disregardLeapYears && isLeapYear(year) ? 29 : 28;
    }
    default: {
      return Number.NaN;
    }
  }
};

// Convert a month number to a month name
const monthString = (monthNumber: number): string => {
  return dateConstants.MONTHS_OF_YEAR[monthNumber];
};

// Convert a month name to a month number (zero-indexed as 0-11)
const monthNumber = (monthString: string): number => {
  const lowercasemonthString = monthString.toLowerCase();
  const uppercasemonthString = monthString.toUpperCase();
  const capitalizedmonthString =
    uppercasemonthString.charAt(0) + lowercasemonthString.slice(1);
  return dateConstants.MONTHS_OF_YEAR.indexOf(capitalizedmonthString);
};

// Returns true if a year number is a leap year
const isLeapYear = (year: number): boolean => {
  return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
};

// Returns today's date with the time set to 0 (12 am)
const today = (): Date => {
  const date = new Date();

  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);

  return date;
};

// Outputs the date as a string that is compatible with HTML date inputs (e.g. for input binding in Vue)
const toHtmlDateInputString = (date: Date): string => {
  return date.toString().split('/').reverse().join('-');
};

// Outputs a string containing the month name and full year, e.g. "January 2022"
const monthNameAndYear = (date: Date): string => {
  return `${monthString(date.getMonth())} ${date.getFullYear()}`;
};

const toString = (date: Date, locale: Locale = Locale.UK): string => {
  const firstPart = locale === Locale.UK ? date.getDate() : date.getMonth() + 1;
  const secondPart =
    locale === Locale.UK ? date.getMonth() + 1 : date.getDate();

  return `${firstPart.toString().padStart(2, '0')}/${secondPart
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;
};

// Returns the start year of the financial year
const financialYear = (date: Date): number => {
  return date.getMonth() + 1 < 4 ||
    (date.getMonth() + 1 === 4 && date.getDate() < 6)
    ? date.getFullYear() - 1
    : date.getFullYear();
};

// Returns the financial year as YYYY/YY+1
const financialYearString = (date: Date): string => {
  const year = financialYear(date);
  return `${year}/${(year + 1).toString().slice(-2)}`;
};

export const dateUtils = {
  parseDateInput,
  compareDateObjects,
  yearsAndCompleteMonths,
  addYears,
  addMonths,
  equals,
  laterOf,
  earlierOf,
  after,
  before,
  beforeOrOn,
  afterOrOn,
  firstOfNextMonth,
  monthsBetween,
  daysInMonth,
  monthString,
  monthNumber,
  isLeapYear,
  today,
  toHtmlDateInputString,
  monthNameAndYear,
  toString,
  financialYear,
  financialYearString,
};

export default dateUtils;
